<template>
    <b-overlay :show="isLoading" rounded="sm">
        <div class="row">
            <div class="col-md-12">
                <div class="card card-custom card-stretch gutter-b">
                    <div class="card-header border-0 pt-5">
                        <h3 class="card-title align-items-start flex-column">
            <span class="card-label font-weight-bolder text-dark"
            >Modificando datos de la orden</span
            >
                        </h3>
                        <div class="card-toolbar">
                            <a
                                    @click="goList"
                                    class="btn btn-secondary font-weight-bolder font-size-sm mr-3"
                            >
                                Volver
                            </a>
                            <a
                                    @click="sendWelcomeCard"
                                    class="btn btn-primary font-weight-bolder font-size-sm mr-3"
                            >
                                Enviar Carta de Presentación
                            </a>
                        </div>
                    </div>
                    <div class="card-body pb-10 pb-2">

                        <div class="row">
                            <div class="col-md-6">
                                <b-form-group
                                        id="form-training-group"
                                        label="Training"
                                        label-for="input-1"
                                >
                                    <v-label v-if="orderToModify.data.canOnlyExtended==true">{{ orderToModify.data.training.name }}</v-label>
                                    <b-form-select v-else
                                            id="form-training-field"
                                            v-model="orderToModify.data.training.id"
                                            :options="trainings"
                                            required
                                    ></b-form-select>
                                </b-form-group>
                            </div>
                            <div class="col-md-6">
                                <b-form-group
                                        id="form-company-group"
                                        label="Empresa"
                                        label-for="input-2"
                                >
                                    <v-label v-if="orderToModify.data.canOnlyExtended==true">{{ orderToModify.data.enterprise.razon_social_empresa }}</v-label>
                                    <b-form-select v-else
                                            id="form-enterprise-field"
                                            v-model="orderToModify.data.enterprise.id"
                                            :options="enterprises"
                                            required
                                    ></b-form-select>
                                </b-form-group>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <b-form-group
                                        id="form-start_date-group"
                                        label="Fecha de inicio"
                                        label-for="input-3"
                                >
                                    <v-label v-if="orderToModify.data.canOnlyExtended==true">{{ orderToModify.data.start_date | formatDate }}</v-label>
                                    <b-form-datepicker v-else
                                            id="form-start_date-field"
                                            v-model="orderToModify.data.start_date"
                                            v-bind="labels[locale] || {}"
                                            :locale="locale"
                                            class="mb-2"
                                    >
                                    </b-form-datepicker>
                                </b-form-group>
                            </div>
                            <div class="col-md-6">
                                <b-form-group
                                        id="form-end-date-group"
                                        label="Fecha de finalización"
                                        label-for="input-4"
                                >
                                    <b-form-datepicker
                                            id="form-end-date-field"
                                            v-model="orderToModify.data.end_date"
                                            v-bind="labels[locale] || {}"
                                            :locale="locale"
                                            class="mb-2"
                                    >
                                    </b-form-datepicker>
                                </b-form-group>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <b-form-group
                                        id="form-minimal-choice-group"
                                        label="Mínima cantidad obligatoria de cursos a seleccionar"
                                        label-for="input-3"
                                >
                                    <v-label v-if="orderToModify.data.canOnlyExtended==true">{{ orderToModify.data.minimal_choice }}</v-label>
                                    <b-form-select v-else
                                            id="form-minimal-choice-field"
                                            v-model="orderToModify.data.minimal_choice"
                                            :options="minimalChoiceList"
                                    ></b-form-select>
                                </b-form-group>
                            </div>
                        </div>
                        <div class="row d-none">
                            <div class="col-md-6">
                                <b-form-group
                                        id="form-welcome_message-group"
                                        label="Mensaje de bienvenida"
                                        label-for="input-7"
                                >
                                    <v-label v-if="orderToModify.data.canOnlyExtended==true">{{ orderToModify.data.welcome_message }}</v-label>
                                    <b-form-textarea v-else
                                            id="form-welcome_message-field"
                                            v-model="orderToModify.data.welcome_message"
                                            placeholder="Escribe algo..."
                                            rows="1"
                                            max-rows="6"
                                    ></b-form-textarea>
                                </b-form-group>
                            </div>
                            <div class="col-md-6">
                                <b-form-group
                                        id="form-bye_message-group"
                                        label="Mensaje de despedida"
                                        label-for="input-7"
                                >
                                    <v-label v-if="orderToModify.data.canOnlyExtended==true">{{ orderToModify.data.bye_message }}</v-label>
                                    <b-form-textarea v-else
                                            id="form-bye_message-field"
                                            v-model="orderToModify.data.bye_message"
                                            placeholder="Escribe algo..."
                                            rows="1"
                                            max-rows="6"
                                    ></b-form-textarea>
                                </b-form-group>
                            </div>
                        </div>
                        <div class="row mt-2" data-app>
                            <div class="col-md-12 mt-7">
                                <b-form-group
                                        id="input-group-description"
                                        label-for="input-2"
                                >
                                    <template v-slot:label>
                                        <h4>Listado de alumnos</h4>
                                    </template>
                                    <v-text-field
                                            v-model="studentSearch"
                                            label="Buscar"
                                            append-icon="search"
                                            class="mx-4"
                                            single-line
                                            hide-details
                                    ></v-text-field>
                                    <v-data-table
                                            :headers="headers"
                                            :items="studentList"
                                            item-key="rut"
                                            :loading="isLoadingTable"
                                            :footer-props="{
                                              itemsPerPageOptions: [5, 10, 25, 50],
                                              showFirstLastPage: true,
                                              itemsPerPageText: 'Resultados por página'
                                          }"
                                            :items-per-page="itemsPerPage"
                                            class="elevation-1"
                                            locale="es"
                                            :search="studentSearch"
                                            show-select
                                            v-model="studentSelected"
                                    >
                                        <template v-slot:item.actions="{ item }">
                                            <div class="text-center">
                                                <a v-if="isGoCancel(item.id)"
                                                   @click="notCancelConfirmation(item.id)"
                                                   class="btn btn-icon btn-light btn-sm"
                                                   title="Listo para cancelar"
                                                >
                                                <span class="svg-icon svg-icon-md svg-icon-primary">
                                                  <!--begin::Svg Icon-->
                                                  <inline-svg
                                                          src="media/svg/icons/Navigation/Check.svg"
                                                          aria-label="Listo para cancelar"
                                                  ></inline-svg>
                                                </span>
                                                </a>
                                                <a v-if="!isGoCancel(item.id) && item.traininginfo.canCancel"
                                                   @click="cancelConfirmation(item.id)"
                                                   class="btn btn-icon btn-light btn-sm"
                                                   title="Cancelar"
                                                >
                                                <span class="svg-icon svg-icon-md svg-icon-primary">
                                                  <!--begin::Svg Icon-->
                                                  <inline-svg
                                                          src="media/svg/downloaded/red-cross-icon.svg"
                                                          aria-label="Cancelar"
                                                  ></inline-svg>
                                                </span>
                                                </a>
                                                <a v-if="isGoDelete(item.id)"
                                                   @click="notDeleteConfirmation(item.id)"
                                                   class="btn btn-icon btn-light btn-sm"
                                                   title="Listo para eliminar"
                                                >
                                                <span class="svg-icon svg-icon-md svg-icon-primary">
                                                  <!--begin::Svg Icon-->
                                                  <inline-svg
                                                          src="media/svg/icons/Navigation/Check.svg"
                                                          aria-label="Listo para eliminar"
                                                  ></inline-svg>
                                                </span>
                                                </a>
                                                <a v-if="!isGoDelete(item.id) && item.traininginfo.canDelete"
                                                   @click="deleteConfirmation(item.id)"
                                                   class="btn btn-icon btn-light btn-sm"
                                                   title="Eliminar"
                                                >
                                                <span class="svg-icon svg-icon-md svg-icon-primary">
                                                  <!--begin::Svg Icon-->
                                                  <inline-svg
                                                          src="media/svg/icons/General/Trash.svg"
                                                          aria-label="Eliminar"
                                                  ></inline-svg>
                                                </span>
                                                </a>
                                            </div>
                                        </template>
                                        <template slot="no-data">
                                            Ningún dato para mostrar
                                        </template
                                        >
                                    </v-data-table>
                                </b-form-group>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 text-right">
                                <b-button type="button" variant="primary"
                                          v-b-modal.modal-save>Guardar
                                </b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="modal-delete" title="¿Estas seguro?">
            El estudiante será eliminado de esta orden.
            <template #modal-footer="{ ok, cancel, hide }">
                <b-button size="sm" variant="primary" @click="cancel()">
                    Cancelar
                </b-button>
                <b-button size="sm" variant="danger" @click="deleteItem(), ok()">
                    Aceptar
                </b-button>
            </template>
        </b-modal>
        <b-modal id="modal-cancel" title="¿Estas seguro?">
            El estudiante será cancelado de esta orden.
            <template #modal-footer="{ ok, cancel, hide }">
                <b-button size="sm" variant="primary" @click="cancel()">
                    Cancelar
                </b-button>
                <b-button size="sm" variant="danger" @click="cancelItem(), ok()">
                    Aceptar
                </b-button>
            </template>
        </b-modal>
        <b-modal id="modal-save" title="¿Estas seguro?">
            Se guardaran todos los cambios realizados en la orden
            <template #modal-footer="{ ok, cancel, hide }">
                <b-button size="sm" variant="primary" @click="cancel()">
                    Cancelar
                </b-button>
                <b-button size="sm" variant="danger" @click="saveOrder(), ok()">
                    Aceptar
                </b-button>
            </template>
        </b-modal>
    </b-overlay>
</template>

<script>
    import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
    import {
        ORDERS_GET_TRAININGS_LIST,
        ORDERS_GET_ENTERPRISE_LIST,
        ORDERS_UPDATE_ORDER,
        ORDERS_SEND_WELCOME_CARD,
    } from "@/store/modules/orders.module";
    import {ORDERS_GET_ORDER} from "../../../store/modules/orders.module";

    export default {
        name: "OrderDetail",
        data() {
            return {
                isLoading: false,
                isLoadingTable: false,
                currentPage: 1,
                itemsPerPage: 10,
                options: {},
                headers: [
                    {text: "RUT", value: "rut", align: " d-none", sortable: false},
                    {text: "DV", value: "dv", align: " d-none", sortable: false},
                    {text: "RUT", value: "fullRutWithHypend", align: "start", sortable: false},
                    {text: "Nombre", value: "name", sortable: false},
                    {text: "Apellido", value: "last_name", align: "start", sortable: false},
                    {text: "Correo empresa", value: "email", align: "start", sortable: false},
                    {text: "Teléfono empresa", value: "phone", sortable: false},
                    {text: "Estado", value: "traininginfo.status.name", sortable: false},
                    {text: "Acciones", value: "actions", sortable: false, align: "center"},
                ],
                locale: "es-ES",
                locales: [{value: "es-ES", text: "Español ES (es-ES)"}],
                labels: {
                    "es-ES": {
                        labelPrevDecade: "Década anterior",
                        labelPrevYear: "Año anterior",
                        labelPrevMonth: "El mes pasado",
                        labelCurrentMonth: "Mes actual",
                        labelNextMonth: "Próximo mes",
                        labelNextYear: "El próximo año",
                        labelNextDecade: "La próxima década",
                        labelToday: "hoy dia",
                        labelSelected: "Fecha seleccionada",
                        labelNoDateSelected: "Sin fecha elegida",
                        labelCalendar: "Calendario",
                        labelNav: "Navegación de calendario",
                        labelHelp:
                            "Utilice las teclas de flecha para navegar por el calendario",
                    },
                },
                idToDelete: null,
                idToCancel: null,
                studentListPaginated: [],
                studentSelected: [],
                studentSearch: "",
                selectedOrder: {},
                orderToModify: {
                    data: {},
                    students: {
                        toCancel: [],
                        toDelete: []
                    }
                },
            };
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Ordenes", route: "orders"},
                {title: "Datos de la orden"},
            ]);
            this.$store.dispatch(ORDERS_GET_TRAININGS_LIST, {
                page: this.currentPage,
                itemsPerPage: this.itemsPerPage
            })
            this.$store.dispatch(ORDERS_GET_ENTERPRISE_LIST, {
                page: this.currentPage,
                itemsPerPage: this.itemsPerPage
            })
            let scope = this
            this.$store.dispatch(ORDERS_GET_ORDER, {
                id: this.$store.state.orders.selectedOrderId
            }).then((orderSelected) => {
                scope.selectedOrder = orderSelected
            });
        },
        methods: {
            goList() {
                this.$router.push("orders");
            },
            sendWelcomeCard() {
                let scope = this
                this.isLoading = true
                this.$store.dispatch(ORDERS_SEND_WELCOME_CARD, {
                    "student_ids": this.studentSelected.map(function (student) {
                        return student.id
                    }),
                }).then((response) => {
                    if(response.status === true) {
                        scope.isLoading = false;
                        this.studentSelected = []
                        scope.$bvToast.toast(
                            response.message,
                            {
                                title: "Modificar Orden",
                                variant: "success",
                                solid: true,
                            }
                        );
                        scope.$router.push("orders");
                    }else{
                        scope.isLoading = false;

                        scope.$bvToast.toast(
                            response.message,
                            {
                                title: "Modificar Orden",
                                variant: "warning",
                                solid: true,
                            }
                        );
                    }
                }).catch(() => {
                    scope.$bvToast.toast(
                        "El servidor devolvio un error.",
                        {
                            title: "Modificar Orden",
                            variant: "danger",
                            solid: true,
                        }
                    );
                });
            },
            deleteConfirmation(id) {
                this.idToDelete = id;
                this.deleteItem()
            },
            cancelConfirmation(id) {
                this.idToCancel = id;
                this.cancelItem()
            },
            notCancelConfirmation(id) {
                this.orderToModify.students.toCancel.splice(this.orderToModify.students.toCancel.indexOf(id), 1)
            },
            notDeleteConfirmation(id) {
                this.orderToModify.students.toDelete.splice(this.orderToModify.students.toDelete.indexOf(id), 1)
            },
            deleteItem() {
                this.orderToModify.students.toDelete.push(this.idToDelete)
            },
            cancelItem() {
                this.orderToModify.students.toCancel.push(this.idToCancel)
            },
            isGoCancel(id) {
                return this.orderToModify.students.toCancel.indexOf(id) > -1
            },
            isGoDelete(id) {
                return this.orderToModify.students.toDelete.indexOf(id) > -1
            },
            saveOrder() {
                //** No es necesario enviar los datos de los estudiantes */
                this.orderToModify.data.students = null

                this.$store.dispatch(ORDERS_UPDATE_ORDER, {
                    id: this.orderToModify.data.id,
                    order: this.orderToModify.data,
                    ...this.orderToModify.students
                })
                    .then(() => {
                        this.$router.push("orders")
                    })
            },
        },
        computed: {
            studentList() {
                return this.selectedOrder.students;
            },
            enterprises() {
                return this.$store.state.orders.orderEnterpriseList.map((item) => {
                    return {text: item.razon_social_empresa, value: item.id};
                });
            },
            trainings() {
                return this.$store.state.orders.orderTrainingList.map((item) => {
                    return {text: item.name, value: item.id};
                });
            },
            minimalChoiceList() {
                return [1, 2, 3, 4, 5]
            },
        },
        watch: {
            selectedOrder(newValue, oldValue) {
                this.orderToModify.data = newValue
            }
        },
    };
</script>

<style scoped></style>
